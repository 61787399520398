import * as React from 'react';
import { useTranslation } from '@external/react-i18next';
import { ServerError } from '../';

export const ErrorPage500: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ServerError
        subtitle={t(
          'error-page.500.subtitle',
          'There was an error during your request.'
        )}
      >
        <p>
          {t(
            'error-page.500.children',
            'Please try one of the following options:'
          )}
        </p>
      </ServerError>
    </>
  );
};
